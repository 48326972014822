<template>
  <component
    :is="type"
    :to="to ? to : null"
    :type="submit ? 'submit' : null"
    class="btn"
    :disabled="disabled"
    @click="checkAnchor($event)"
    :class="{
      'btn--green': green,
      'btn--blue': blue,
      'btn--red': red,
      'btn--beige': beige,
      'btn--black': black,
      'btn--gray': gray,
      'btn--white': white,
      'btn--full': fullWidth,
      'btn--link': link,
      'btn--link-xs': linkXs,
      'btn--disabled': disabled,
      'btn--center': center,
      'btn--small': small,
      'btn--tiny': tiny,
      'btn--xs': xs,
      'btn--no-margin': noMargin,
      'btn--secondary': secondary,
      'btn--icon': icon,
      'btn--underline': underline,
    }"
  >
    <span
      v-if="icon && !link"
      class="btn__icon"
      :class="[`icon-${icon}`]"
    ></span>
    <slot v-if="!loading" />
    <span v-else>Warte...</span>
    <span
      v-if="icon && link"
      class="btn__icon"
      :class="[`icon-${icon}`]"
    ></span>
  </component>
</template>

<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const props = defineProps({
  type: {
    default: "button",
    type: [String, Object, Array],
  },
  to: {
    type: String,
    default: "",
  },
  href: {
    type: String,
    default: "",
  },
  link: {
    type: Boolean,
    default: false,
  },
  linkXs: {
    type: Boolean,
    default: false,
  },
  green: {
    type: Boolean,
    default: false,
  },
  blue: {
    type: Boolean,
    default: false,
  },
  beige: {
    type: Boolean,
    default: false,
  },
  red: {
    type: Boolean,
    default: false,
  },
  black: {
    type: Boolean,
    default: false,
  },
  gray: {
    type: Boolean,
    default: false,
  },
  white: {
    type: Boolean,
    default: false,
  },
  fullWidth: {
    type: Boolean,
    default: false,
  },
  center: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  tiny: {
    type: Boolean,
    default: false,
  },
  xs: {
    type: Boolean,
    default: false,
  },
  submit: {
    type: Boolean,
    default: false,
  },
  noMargin: {
    type: Boolean,
    default: false,
  },
  anchor: {
    type: Boolean,
    default: false,
  },
  secondary: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  underline: {
    type: Boolean,
    default: false,
  },
  icon: {
    type: String,
    default: "",
  },
});

const checkAnchor = (event) => {
  if (
    props.href &&
    props.href.startsWith("#") &&
    document.querySelector(props.href)
  ) {
    event.preventDefault();
    const url = new URL(location);
    url.hash = props.href;
    history.replaceState(null, null, url);
    window.scrollTo({
      top: document.querySelector(props.href).offsetTop - 100,
      behavior: "smooth",
    });
  } else {
    return null;
  }
};

onMounted(() => {});
</script>

<style lang="scss">
.btn-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: $spacing-sm;

  > .btn {
    margin: 0 !important;
  }
}

.btn {
  padding: $spacing-base;
  border-radius: $radius-small;
  border-width: 2px;
  border-color: $color-black;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: $spacing-xxs;
  text-align: center;
  // margin-bottom: $spacing-xs;
  line-height: 1;
  font-size: $text-base;
  max-width: $xs;
  cursor: pointer;

  &:after {
    width: 0%;
    content: "";
    position: absolute;
    inset: -1px;
    z-index: -1;
    border-radius: $radius-small;
  }

  @media (hover: hover) {
    &:hover {
      color: $color-white;
      position: relative;
      z-index: 0;

      &:after {
        transition: all 0.3s ease-in-out;
        width: calc(100% + 2px);
        background: $color-black;
      }
    }
  }

  &[type="submit"] {
    background-color: inherit;

    &.btn--green {
      background-color: $color-green;
    }
    &.btn--blue {
      background-color: $color-blue;
    }
    &.btn--black {
      background-color: $color-black;
    }
    &.btn--red {
      background-color: $color-red;
    }
    &.btn--gray {
      background-color: $color-light-gray;
    }
  }

  // & + & {
  //   margin-left: $spacing-xs;
  // }

  span {
    margin-right: $spacing-xxs;
  }

  &--no-margin {
    margin: 0;
    margin-bottom: 0px;
  }

  &--small {
    font-size: $text-base;
    padding: 14px $spacing-base;
  }

  &--xs,
  &--tiny {
    font-size: $text-md;
    padding: $spacing-xs $spacing-sm;
  }

  &--link {
    padding-left: 0px;
    padding-right: 0px;
    padding-top: $spacing-xxxs;
    padding-bottom: $spacing-xxxs;
    border-width: 0px;
    border-radius: 0px;
    background: none !important;
    border: none;
    font-size: inherit;

    @media (hover: hover) {
      &:hover {
        color: $color-dark-gray;
        border-color: $color-dark-gray;

        &:after {
          content: none;
        }
      }
    }
  }

  &--link-xs {
    color: $color-dark-gray;
    font-size: $text-xs;
    border-color: $color-dark-gray;
  }

  &--full {
    display: flex;
    width: 100%;
    margin: 0 auto;
    max-width: unset;

    & + & {
      margin-left: 0px;
    }
  }

  &--black {
    background-color: $color-black;
    border-color: $color-black;
    color: $color-white;

    @media (hover: hover) {
      &:hover {
        // color: $color-black;

        &:after {
          background: $color-dark-gray;
        }
      }
    }
  }

  &--gray {
    background-color: $color-light-gray;
    border-color: $color-light-gray;
    color: $color-black;

    &.btn--link {
      color: $color-dark-gray;
    }

    @media (hover: hover) {
      &:hover {
        color: $color-black;

        &:after {
          background: darken($color-light-gray, 20%);
        }
      }
    }
  }

  &--white {
    background-color: $color-white;
    border-color: $color-white;
    color: $color-black;

    &.btn--link {
      color: $color-white;
    }

    @media (hover: hover) {
      &:hover {
        color: $color-black;

        &:after {
          background: darken($color-light-gray, 20%);
        }
      }
    }

    &.btn--secondary {
      background: transparent;
      color: $color-white;
    }
  }

  &--green {
    background-color: $color-green;
    border-color: $color-green;
    color: $color-white;

    &.btn--link {
      color: $color-green;
    }

    @media (hover: hover) {
      &:hover {
        // color: $color-black;

        &:after {
          background: darken($color-green, 20%);
        }
      }
    }

    &.btn--secondary {
      background: transparent;
      color: $color-green;
    }
  }
  &--blue {
    background-color: $color-blue;
    border-color: $color-blue;
    color: $color-white;

    @media (hover: hover) {
      &:hover {
        // color: $color-black;

        &:after {
          background: darken($color-blue, 20%);
        }
      }
    }

    &.btn--secondary {
      background: transparent;
      color: $color-blue;
    }
  }

  &--red {
    background: $color-red;
    border-color: $color-red;
    color: $color-white;

    &.btn--link {
      color: $color-red;
    }

    @media (hover: hover) {
      &:hover {
        // color: $color-black;

        &.btn--secondary {
          color: $color-white;
        }

        &:after {
          background: darken($color-red, 20%);
        }
      }
    }

    &.btn--secondary {
      background: transparent;
      color: $color-red;
    }
  }

  &--beige {
    background-color: $color-beige;
    border-color: $color-beige;
    color: $color-white;

    &.btn--link {
      color: $color-beige;
    }

    @media (hover: hover) {
      &:hover {
        // color: $color-black;

        &:after {
          background: darken($color-beige, 20%);
        }
      }
    }

    &.btn--secondary {
      background: transparent;
      color: $color-beige;
    }
  }

  &--disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &--icon {
    padding: $spacing-md $spacing-base;

    &.btn--small {
      padding: $spacing-sm $spacing-base;
    }

    &.btn--tiny {
      padding: $spacing-xxxs $spacing-sm;
    }
  }

  &--underline {
    border-bottom: 2px solid currentColor;
  }
}

.btn__icon {
}
</style>
