export function checkHydration() {
  const temp = useTempStore();

  const isHydrating = computed(() => {
    return temp.getIsHydrating;
  });
  const finishHydrating = computed(() => {
    return !temp.getIsHydrating;
  });

  return {
    isHydrating,
    finishHydrating,
  };
}
